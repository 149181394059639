import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/render/project/src/src/components/BlogLayout/index.jsx";
import BlogPostMetaData from "../../../../components/BlogPostMetaData";
export const pageMetaData = {
  pageTitle: "",
  pageDescription: "How Expensive Was Lockdown?",
  pageMetaTags: []
};
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  pageMetaData,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`How Expensive Was Lockdown?`}</h1>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/3961bf452dafd41d3bf023fdb5f32d86/c08c5/how-expensive-was-lockdown.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAUCAwT/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAv/aAAwDAQACEAMQAAABhYuaSyjAl//EABoQAAIDAQEAAAAAAAAAAAAAAAECAAMTESL/2gAIAQEAAQUCRRivu2wENo4lC9QKJ//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABURAQEAAAAAAAAAAAAAAAAAAAEQ/9oACAECAQE/AUn/xAAcEAACAgIDAAAAAAAAAAAAAAAAAQIhERIQQWH/2gAIAQEABj8CjPtWNuo+lGNmbSeeP//EABwQAQACAwADAAAAAAAAAAAAAAEAIRExUUFx0f/aAAgBAQABPyEVppfI3MTKIKAUCKGl7mWRyPiKzvfZ/9oADAMBAAIAAwAAABCgL//EABURAQEAAAAAAAAAAAAAAAAAABAh/9oACAEDAQE/EIf/xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAgEBPxAR/8QAGRABAAMBAQAAAAAAAAAAAAAAAQARITFR/9oACAEBAAE/EHAPVHe7TyXdh47uDHCWbsKihSBStaRkKXScAwCif//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "How Expensive Was Lockdown?",
            "title": "How Expensive Was Lockdown?",
            "src": "/static/3961bf452dafd41d3bf023fdb5f32d86/c08c5/how-expensive-was-lockdown.jpg",
            "srcSet": ["/static/3961bf452dafd41d3bf023fdb5f32d86/f93b5/how-expensive-was-lockdown.jpg 300w", "/static/3961bf452dafd41d3bf023fdb5f32d86/b4294/how-expensive-was-lockdown.jpg 600w", "/static/3961bf452dafd41d3bf023fdb5f32d86/c08c5/how-expensive-was-lockdown.jpg 640w"],
            "sizes": "(max-width: 640px) 100vw, 640px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <BlogPostMetaData postDate={props.pageContext.frontmatter.date} category={props.pageContext.frontmatter.blogCategory} mdxType="BlogPostMetaData" />
    <p>{`The last several months have seen a large majority of office workers making the adjustment to work from home, and whether you've been working at your home office or setting up camp on your living room couch, you've probably been logging more electricity use than usual. While many parts of the country are transition back to in-office work, other parts - and some employees - are opting to work from home. How expensive has lockdown been for your home electricity cost? And is there anything you can do about it?`}</p>
    <p>{`During the height of quarantine, there was a definite uptick in residential electric usage across the nation, with some estimates charting a 6% to 12% increase in electric use. Of course, this makes sense; the more you are at home, the more electricity you're going to be using, especially if the home is now a place for work and school. But how expensive was this?`}</p>
    <p>{`For many Americans, home electricity costs have spiked between $10 and $30 dollars per bill cycle. Depending on where you live, that spike might have been bigger, such as in more highly populated areas like New York or the West Coast. But even states like Texas have seen surges in utility costs, and these costs aren't going anywhere anytime soon. There are projections that utility costs will rise another few bucks and stay there, probably for the long haul, and that means you might want to start thinking about how you can off-set this increase.`}</p>
    <h2>{`Compare Your Pre-Quarantine and Post-Quarantine Electric Bills`}</h2>
    <p>{`Not sure if your home electricity cost has risen? It's easy to check. All you need to do is compare one of your pre-quarantine bills to one of your post-quarantine bills; the bill from before you started working from home is your baseline, which you can compare to the bill that includes the period where your home became your office. If there is any significant change in your bill, then you can probably attribute the increase to the lockdown mandates.`}</p>
    <h2>{`Can You Ask Your Company to Reimburse Your Home Electricity Cost?`}</h2>
    <p>{`For employees who are working from home, the one burning question is whether or not you can ask your company to reimburse your electric bill, even partially. The good news is that, even if your company won't reimburse you, you can write off your electricity bill on this year's taxes as an expense deductible for your home office.`}</p>
    <p>{`But as for the company reimbursing you? Well, that depends on the company, your specific circumstances, and a few other facts. We'll explain.`}</p>
    <h3>{`If Work From Home Was Required`}</h3>
    <p>{`If your company required you to work from home, as in they closed down the physical office and had a company-wide mandate to make yourself a productive employee at home, then it's more likely that your company will be willing to discuss reimbursement costs. Depending on your position within the company and the company's policy, you might be able to reimburse a good chunk of your electric bill.`}</p>
    <h3>{`If Work From Home Was Optional`}</h3>
    <p>{`If you decided to work from home and the company allowed you to do so, then you might not be able to ask for a reimbursement. This is because you made the choice to work from home, thus voluntarily increasing your electric bill. The option to work at the office, where electricity was free, was always on the table even if you made a different choice. You might ask your company about reimbursement in this case, but don't hold out too much hope.`}</p>
    <h3>{`Other Factors`}</h3>
    <p>{`Even if your company had a mandate to work from home, they might not be too eager to reimburse all or part of your electricity bill. Why? Things like state laws, which can regulate what a company can or can't reimburse, may be the cause. It may also be because you are not a full-time employee of the company or the company could argue that your use of electricity wasn't required to complete your assigned work. There are loopholes that companies might use, so make sure to read up on your state laws before you bring your proposal to your boss.`}</p>
    <h2>{`Should You Consider Switching Utility Companies to Save Money?`}</h2>
    <p>{`If you're planning on working from home for the foreseeable future, you might want to consider switching to a different home electricity provider, as a different utility company might have lower prices. It's worth the effort to find out if you can switch and save.`}</p>
    <p>{`Lockdown was expensive in many ways, including moderately more expensive for your home electricity costs. Since your utility costs may continue to rise due to expenses related to working from home, you may want to look into asking your company to reimburse your electricity bill - or switch to a different utility provider altogether. To learn more about the expenses of lockdown and how to compare and save, visit `}<a parentName="p" {...{
        "href": "/"
      }}>{`The Comparison Company`}</a>{` .`}</p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      